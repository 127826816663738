.wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.filter {
  width: 19%;
  min-width: 15rem;
  margin-bottom: 0.8rem;
}

.category button[aria-label="Clear"] {
  display: none;
}

.datepicker {
  width: 19%;
  min-width: 15rem;
  margin-top: -0.8rem !important;
  margin-bottom: 0.8rem;
}