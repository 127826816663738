.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding-bottom: 4rem;
}

.box1, .box2, .box3, .box4 {
  margin: 0.3rem;
}

.box1 {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 6;
}

.box2 {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 6;
}

.box3 {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 6;
  grid-row-end: 10;
}

.box4 {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 6;
  grid-row-end: 10;
}
