.wrap {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  width: 90%;
  max-width: 20rem;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.logo {
  margin-bottom: 1.5rem;
}

.input {
  margin-top: 0.8rem !important;
}

.button {
  margin-top: 0.8rem !important;
}

.contact {
  margin-top: 2rem;
  color: var(--color-primary);
}

.forgotPassword {
  margin-top: 1rem;
  color: var(--color-primary);
}

.content a:hover {
  text-decoration: underline;
}

.loader {
  color: white;
}

.alert {
  width: 100%;
  margin-top: 2rem;
}

.checkbox {
  width: 100%;
  margin-top: 0.8rem;
  margin-left: 0;
}