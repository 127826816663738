.wrapper {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.box1, .box2 {
  margin: 0.3rem;
}

.box1 {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 8;
}

.box2 {
  grid-column-start: 5;
  grid-column-end: 8;
  grid-row-start: 1;
  grid-row-end: 8;
}