.header {
  width: 100%;
  background-color: black;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9999;
}

.logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  font-size: 1.35rem;
}

.logo h1 {
  font-size: 1.7em;
  color: white;
}

.menuButton {
  position: absolute !important;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
}

.menuIcon {
  width: 2rem !important;
  height: 2rem !important;
  color: white;
}