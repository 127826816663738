.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.box1, .box2, .box3, .box4 {
  margin: 0.3rem;
}

.box1 {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 5;
}

.box2 {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 5;
}

.box3 {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 5;
  grid-row-end: 9;
}

.box4 {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 5;
  grid-row-end: 9;
}

.monthEquivalent {
  text-align: center;
}

.monthEquivalentLabel {
  width: 70%;
  margin: 0.1rem;
}