.wrap {
  height: 100%;
  border-radius: 0.8rem;
  padding: 0.5rem;
  position: relative;
  box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.7);
  border-radius: 0.5rem;
}

.graph {
  height: 20rem;
  width: 100%;
  display: flex;
}

.isLoading {
  padding: 0 0 1rem;
  margin-bottom: 1rem;
  font-style: italic;
}

.header {
  position: relative;
}

.legend {
  max-width: 50%;
  position: absolute;
  top: 1rem;
  right: 2rem;
  display: flex;
  flex-direction: row;
  column-gap: 2rem;
  font-family: Roboto;
  font-size: 0.85rem;
  font-style: normal;
  letter-spacing: 0;
  color: black;
}

.legend > .item {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.5rem;
}

.legend > .item > .square {
  width: 0.85rem;
  height: 0.85rem;
}
