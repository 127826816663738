html {
  font-size: 16px;
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  --color-primary: #00B0F0;
  --color-primary-transparent: #00b0f081;
  --box-shadow: 0 0.1rem 0.25rem rgb(0, 0, 0, 0.3);
  --box-shadow-hard: 0 0.2rem 0.4rem rgb(0, 0, 0, 0.6);
}

div {
  box-sizing: border-box;
}

p, span, h1, h2, h3, h4, a, li, imagecaption, button {
  font-family: Roboto;
  font-size: inherit;
  margin: 0;
  padding: 0;
  color: black;
}

a {
  text-decoration: none;
}

@media only screen and (max-width: 1530px) {
  html {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1300px) {
  html {
    font-size: 13px;
  }
}

@media only screen and (max-width: 900px) {
  html {
    font-size: 16px;
  }
}