.wrap {
  width: 100%;
  border-radius: 0.8rem;
  padding: 0.5rem;
  position: relative;
  box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.7);
  border-radius: 0.5rem;
}

.header {
  max-width: 19rem;
}

.marginTop {
  margin-top: 1rem;
}

.p1 {
  display: inline-block;
  text-align: left;
  width: 45%;
  margin-left: 5%;
}

.p2 {
  display: inline-block;
  text-align: right;
  width: 45%; 
}

.p1, .p2 {
  font-family: Roboto;
  letter-spacing: 0;
  color: black;
}

.loaderDiv {
  height: 38.15rem;
}

.isLoading {
  font-style: italic;
}
