.wrap {
  padding: 0.5rem;
  position: relative;
  box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.7);
  border-radius: 0.5rem;
}

.graph {
  height: 14rem;
  width: 100%;
  display: flex;
}

.isLoading {
  padding: 0 0 1rem;
  margin-bottom: 1rem;
  font-style: italic;
}
