.mainContainer{
  padding-top: 0.5rem;
  overflow-x: hidden;
}

.topContainer {
  display: flex;
  padding-top: 0.5rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
}

.topsChild {
  flex: 1;
  -webkit-box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.7);
  box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.7);
  border-radius: 0.5rem;
}  

.topsChild:first-child {
  margin-right: 0.6rem;
}

.middleContainer {
  padding-top: 0.8rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  margin-bottom: 0.8rem;
}
