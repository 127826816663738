.wrap {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu {
  width: 90%;
  max-width: 60rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.item {
  width: 11rem;
  margin: 1rem;
  display: block;
  text-align: center;
  box-shadow: var(--box-shadow);
  border-radius: 0.2rem;
  padding: 1rem;
}

.item:hover {
  color: var(--color-primary);
  cursor: pointer;
}

.item p {
  color: inherit;
  font-size: 1.4rem;
  margin-top: 0.25rem;
}

.icon {
  font-size: 4rem !important;
}