.item {
  min-width: 10rem;
  display: grid;
  grid-template-columns: 55% 45%;
  padding: 0.1rem;
  border-top: solid 1px #ccc;
}

.leftValueContainer, .rightValueContainer {
  min-height: 3.6rem;
  width: 100%;
  display: flex;
  align-self: center;
  justify-self: center;
  justify-content: center;
  align-content: center;
  align-items: center;
  justify-items: center;
  font-size: 1.1rem;
}

.leftValueContainer {
  background-color: skyblue;
  padding: 0.5rem 0.25rem;
  border-bottom-left-radius: 5px;
}

.arrow {
  font-size: 1rem;
  margin-bottom: -2px;
  margin-left: -0.5rem;
}
