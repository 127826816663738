.menu {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 5rem;
  background-color: whitesmoke;
  box-shadow: var(--box-shadow);
  z-index: 999;
  padding-top: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.icon {
  width: 2rem !important;
  height: 2rem !important;
}

.item {
  text-align: center;
  cursor: pointer;
  display: block;
  margin-bottom: 1.5rem;
}

.item.active, .item:hover {
  color: var(--color-primary);
}

.item p {
  margin-top: -0.2rem;
  color: inherit;
  font-size: 0.9rem;
}