.wrap {
  width: 100%;
}

.content {
  width: 100%;
  max-width: 110rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  height: 40rem;
}

.left {
  width: 65%;
  display: inline-block;
  padding-bottom: 3rem;
}

.right {
  width: 34%;
  display: inline-block;
}