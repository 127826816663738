.logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  font-size: 1.35rem;
}

.logo h1 {
  font-size: 1.7em;
  color: black;
}