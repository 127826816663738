.wrap {
  border-radius: 0.8rem;
  padding: 0.5rem;
  position: relative;
  box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.7);
  border-radius: 0.5rem;
}

.intervalStartLabel, .intervalLengthLabel {
  width: 70%;
  margin: 0.1rem;
}

.intervalStartInput {
  width: 30%;
  margin: 0.1rem;
}

.graph {
  height: 14rem;
  width: 100%;
  display: flex;
}

.header, .optionDiv {
  display: inline-block;
}

.header {
  width: 75%;
}

.optionDiv{
  width: 25%;
}

.isLoading {
  padding: 0 0 1rem;
  margin-bottom: 1rem;
  font-style: italic;
}
