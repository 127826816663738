.wrap {
  border-radius: 0.8rem;
  padding: 0.5rem;
  margin-left: auto;
  box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.7);
  border-radius: 0.5rem;
}

.graph {
  height: 37.5rem;
  width: 100%;
  display: flex;
}

.header {
  max-width: 19rem;
}

.isLoading {
  font-style: italic;
}
