.wrap {
  width: 100%;
  height: 7rem;
}

.content {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-bottom: 5rem;
}

.left {
  width: 65%;
}

.right {
  width: 34%;
}