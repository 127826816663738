.wrap {
  width: 100%;
  padding: 0.5rem;
  position: relative;
  box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.7);
  border-radius: 0.5rem;
  display: grid;
  align-items: center;
  font-family: 'Poppins', sans-serif;
}

.header {
  max-width: 19rem;
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
}

.tree {
  width: 100%;
  height: auto;
  margin-top: 3rem;
  text-align: center;
}

.tree ul {
  padding-top: 1.25rem;
  position: relative;
  transition: .5s;
  
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
}

.tree li {
  display: inline-table;
  text-align: center;
  list-style-type: none;
  position: relative;
  padding: 0.625rem;
  transition: .5s;
}

.tree li::before, .tree li::after {
  content: '';
  position: absolute;
  top: 0;
  right: 50%;
  border-top: 1px solid #ccc;
  width: 51%;
  height: 0.625rem;
}

.tree li::after {
  right: auto;
  left: 50%;
  border-left: 1px solid #ccc;
}

.tree li:only-child::after, .tree li:only-child::before {
  display: none;
}

.tree li:only-child {
  padding-top: 0;
}

.tree li:first-child::before, .tree li:last-child::after {
  border: 0 none;
}

.tree li:last-child::before {
  border-right: 1px solid #ccc;
  border-radius: 0 0.313rem 0 0;
  -webkit-border-radius: 0 0.313rem 0 0;
  -moz-border-radius: 0 0.313rem 0 0;
}

.tree li:first-child::after {
  border-radius: 0.313rem 0 0 0;
  -webkit-border-radius: 0.313rem 0 0 0;
  -moz-border-radius: 0.313rem 0 0 0;
}

.tree ul ul::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 1px solid #ccc;
  width: 0;
  height: 1.25rem;
}

.tree li p {
  border: 1px solid #ccc;
  display: inline-grid;
  border-radius: 0.313rem;
  text-decoration-line: none;
  border-radius: 0.313rem;
  transition: .5s;
}

.tree li p span {
  border-radius: 0.313rem;
  color: black;
  padding: 0.5rem;
  font-size: 1rem;
  letter-spacing: 1px;
  font-weight: 600;
}

.tree li p:hover, .tree li p:hover i, .tree li p:hover span, .tree li p:hover+ul li p {
  background: #c8e4f8;
  color: #000;
  border: 1px solid #94a0b4;
}

.tree li p:hover+ul li::after, .tree li p:hover+ul li::before, .tree li p:hover+ul::before, .tree li p:hover+ul ul::before {
  border-color: #94a0b4;
}
