.middlesChild{
  padding: 0.5rem 0 2rem 1rem;
  font-style: italic;
  box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.7);
  border-radius: 0.5rem;
}

.h1Header{
  font-weight: bold;
}

.transitionInfo{
  margin-bottom: 2rem;
}
