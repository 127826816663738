.nextPage {
  min-width: 11.5rem;
  margin-left: -3.5rem;
  background-color: lightgray;
  color: black;
  text-align: center;
  border-radius: 1rem;
  padding: 1rem;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 1rem;
}

.nextPage>a:hover {
  cursor: pointer;
  color: white;
  font-weight: bold;
}
