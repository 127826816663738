.menuDrawer {
  z-index: 99999 !important;
}

.container {
  width: 20rem;
  max-width: 70vw;
  font-size: 1.5rem;
  padding-left: 0.5rem;
}

.container a, .container button {
  margin-left: 0.25rem;
}

.account {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1rem 0 0.5rem 1rem;
}

.account .avatar {
  background-color: transparent;
  border: 0.15rem solid black;
  margin-right: 0.7rem;
  width: 2.2rem;
  height: 2.2rem;
  color: black;
}

.account .email {
  font-size: 0.9rem;
}

.linkButton {
  background: transparent;
  outline: none;
  border: none;
  font-size: inherit;
  cursor: pointer;
}